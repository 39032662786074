import React from 'react';
import { Helmet } from 'react-helmet';

export const NotFoundPageHead = ({ locale, title }) => {
  return (
    <Helmet>
      <html lang={locale} />
      <link rel="icon" href="/favicon-32.png" type="image/png" />
      <title>{title}</title>
    </Helmet>
  );
};